// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceder-js": () => import("./../../../src/pages/acceder.js" /* webpackChunkName: "component---src-pages-acceder-js" */),
  "component---src-pages-cesta-buy-js": () => import("./../../../src/pages/cesta/buy.js" /* webpackChunkName: "component---src-pages-cesta-buy-js" */),
  "component---src-pages-cesta-cart-js": () => import("./../../../src/pages/cesta/cart.js" /* webpackChunkName: "component---src-pages-cesta-cart-js" */),
  "component---src-pages-cesta-login-js": () => import("./../../../src/pages/cesta/login.js" /* webpackChunkName: "component---src-pages-cesta-login-js" */),
  "component---src-pages-cesta-register-js": () => import("./../../../src/pages/cesta/register.js" /* webpackChunkName: "component---src-pages-cesta-register-js" */),
  "component---src-pages-cesta-success-js": () => import("./../../../src/pages/cesta/success.js" /* webpackChunkName: "component---src-pages-cesta-success-js" */),
  "component---src-pages-contrasena-recordar-js": () => import("./../../../src/pages/contrasena-recordar.js" /* webpackChunkName: "component---src-pages-contrasena-recordar-js" */),
  "component---src-pages-fitxa-llibre-js": () => import("./../../../src/pages/fitxa-llibre.js" /* webpackChunkName: "component---src-pages-fitxa-llibre-js" */),
  "component---src-pages-fitxa-objecte-js": () => import("./../../../src/pages/fitxa-objecte.js" /* webpackChunkName: "component---src-pages-fitxa-objecte-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-pedrera-js": () => import("./../../../src/pages/la-pedrera.js" /* webpackChunkName: "component---src-pages-la-pedrera-js" */),
  "component---src-pages-libres-js": () => import("./../../../src/pages/libres.js" /* webpackChunkName: "component---src-pages-libres-js" */),
  "component---src-pages-privado-add-address-js": () => import("./../../../src/pages/privado/add-address.js" /* webpackChunkName: "component---src-pages-privado-add-address-js" */),
  "component---src-pages-privado-ayuda-js": () => import("./../../../src/pages/privado/ayuda.js" /* webpackChunkName: "component---src-pages-privado-ayuda-js" */),
  "component---src-pages-privado-datos-js": () => import("./../../../src/pages/privado/datos.js" /* webpackChunkName: "component---src-pages-privado-datos-js" */),
  "component---src-pages-privado-direcciones-js": () => import("./../../../src/pages/privado/direcciones.js" /* webpackChunkName: "component---src-pages-privado-direcciones-js" */),
  "component---src-pages-privado-favoritos-js": () => import("./../../../src/pages/privado/favoritos.js" /* webpackChunkName: "component---src-pages-privado-favoritos-js" */),
  "component---src-pages-privado-password-js": () => import("./../../../src/pages/privado/password.js" /* webpackChunkName: "component---src-pages-privado-password-js" */),
  "component---src-pages-privado-pedidos-js": () => import("./../../../src/pages/privado/pedidos.js" /* webpackChunkName: "component---src-pages-privado-pedidos-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */)
}

